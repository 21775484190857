import capitalize from 'lodash/capitalize';
import { defaultRegisterServicePlan } from '@/modules/service-plan/tools/mappers';
import HasPlans from '../mixins/HasPlans';

const PlanList = () => import('@/modules/service-plan/components/PlanList.vue');
const PlanListTable = () => import('@/modules/service-plan/components/PlanListTable.vue');

export default {
  name: 'PlanSelector',

  mixins: [
    HasPlans,
  ],

  props: {
    value: {
      type: Object,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    PlanList,
    PlanListTable,
  },

  data() {
    return {
      planListVisible: false,
    };
  },

  computed: {
    sportType() {
      return capitalize(process.env.VUE_APP_SPORT_TYPE);
    },

    hasPrechoosenPlan() {
      return typeof this.value !== 'undefined';
    },

    choosenRegisterPlan() {
      if (!this.hasPrechoosenPlan) {
        return defaultRegisterServicePlan();
      }

      return this.registerServicePlans.find((plan) => plan.level === this.value.level);
    },

    paymentTitle() {
      if (!this.hasPrechoosenPlan || !this.choosenRegisterPlan) {
        // Return if choosing plan is not available or choosen plan is not set yet
        return '';
      }

      const { premium, periods } = this.choosenRegisterPlan;
      if (!premium) {
        // Return static text, if plan is free
        return this.$vuetify.lang.t('$vuetify.servicePlan.freeAccountPlanText');
      }

      if (!periods) {
        // Return if periods is not available (in free plan), or periods array is empty
        return '';
      }

      return periods.map((period) => `$${period.amount}/${period.periodTitle}`).join(' or ');
    },

    levelText() {
      if (!this.hasPrechoosenPlan || !this.choosenRegisterPlan) {
        // Return if choosing plan is not available or choosen plan is not set yet
        return '';
      }

      return this.choosenRegisterPlan.levelText;
    },
    selectedPlan: {
      get() {
        return this.planListItems.find(plan => plan.level === this.value.level);
      },
      set(plan) {
        this.planListVisible = false;
        this.$emit('input', plan);
      },
    },
  },
  methods: {
    chooseLevel() {
      if (!this.disabled) {
        this.planListVisible = true;
      }
    }
  },
};
